<template>
  <div>
    <v-container>
      <v-row
        v-if="!WS_KEY"
        justify="center"
      >
        <v-dialog
          v-model="dialog"
          persistent
          max-width="800px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-row justify="center">
              <v-col
                cols="12"
                md="6"
              >
                <h3 class="centerButtons">
                  Please submit form to access Shipping options
                </h3>
                <v-btn
                  color="primary"
                  v-bind="attrs"
                  class="centerButtons mt-2"
                  v-on="on"
                >
                  Sign up
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Sign Up Agreement</span>
            </v-card-title>
            <v-card-text>
              <v-form v-model="isValid">
                <v-container>
                  <v-row justify="center">
                    <a
                      href="https://www.2ship.com/terms-and-conditions.php"
                      target="_blank"
                    ><h3 class="font-weight-bold">
                      By proceeding you agree to 2ships terms and conditions.
                    </h3>
                    </a>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="dialog = false"
              >
                Close
              </v-btn>
              <v-btn
                color="primary"
                @click="getWsKey"
              >
                Proceed
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <pg-section v-if="WS_KEY">
        <div slot="header">
          Shipping
        </div>
        <div slot="content">
          <h3 class="ml-1 mb-4">
            Search a patient to proceed to ship your package
          </h3>
          <template v-if="!hasData">
            <patient-name-search
              @selected-patient="selectedPatient()"
            ></patient-name-search>
          </template>
          <template v-if="hasData">
            <v-overlay
              :opacity="0.75"
              :value="hasData"
              style="z-index: 30"
            >
              <h2>Loading Data...</h2>
              <v-progress-circular
                class="centerButtons"
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-overlay>
          </template>
        </div>
      </pg-section>
      <!-- TODO: Not sure if we need, we can remove it after reviewing how shipping process will work moving forward -->
      <!-- <v-stepper v-model="step" v-if="WS_KEY">
        <v-stepper-header class="v-stepper_header">
          <v-stepper-step
            edit-icon="check"
            step="1"
            :complete="step > 1"
            id="v-step-0"
            >Patient Search</v-stepper-step
          >
          <v-stepper-step edit-icon="check" step="2" :complete="step > 2"
            >Ship</v-stepper-step
          >
          <v-stepper-step edit-icon="check" step="3" :complete="step > 3"
            >Shipping Card</v-stepper-step
          >
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <pg-section>
              <div slot="header">Patient Search</div>
              <div slot="content">
                <template v-if="!hasData">
                  <patient-name-search
                    @selected-patient="selectedPatient()"
                  ></patient-name-search>
                </template>
                <template v-if="hasData">
                  <v-overlay
                    :opacity="0.75"
                    :value="hasData"
                    style="z-index: 30"
                  >
                    <h2>Loading Data...</h2>
                    <v-progress-circular
                      class="centerButtons"
                      :size="70"
                      :width="7"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </v-overlay>
                </template>
              </div>
            </pg-section>
          </v-stepper-content>

          <v-stepper-content step="2">
            <pg-section>
              <div slot="content">
                <template>
                  <v-flex class="centerButtons mt-3">
                    <h3>
                      Please ship in iFrame to get your latest shipment before
                      continuing
                    </h3>
                  </v-flex>
                  <v-flex class="centerButtons">
                    <v-btn
                      class="mr-2"
                      color="primary"
                      @click.native="previousStep()"
                      >Previous</v-btn
                    >
                    <v-btn color="primary" @click.native="nextStep()"
                      >Continue</v-btn
                    >
                  </v-flex>
                  <iframe
                    id="2ship"
                    title="Ship"
                    onload="window.parent.scrollTo(0,0)"
                    allowtransparency="true"
                    allowfullscreen="true"
                    allow="geolocation; microphone; camera"
                    :src="iframeUrl"
                    frameborder="0"
                    style="
                      width: 1px;
                      min-width: 100%;
                      min-height: 100vh;
                      border: none;
                    "
                    scrolling="yes"
                  ></iframe>
                </template>
              </div>
            </pg-section>
          </v-stepper-content> -->

      <!-- <v-stepper-content step="3">
            <pg-section>
              <div slot="header">Shipping Card</div>
              <div slot="content">
                <template
                  v-if="
                    shipmentInfo &&
                    shipmentInfo.shippingDoc &&
                    shipmentInfo.shippingDoc.ShipmentDetails
                  "
                >
                  <v-card>
                    <v-card-title>Shipment Information</v-card-title>
                    <v-list>
                      <v-list-item>
                        <h3>
                          <a :href="`${shipmentInfo.labelUrl}`" target="_blank"
                            >Click here to get your Shipping Label</a
                          >
                        </h3>
                      </v-list-item>
                      <v-list-item>
                        <h3>
                          Tracking Number:
                          {{ shipmentInfo.trackingNumberID }}
                        </h3>
                      </v-list-item>
                      <v-list-item>
                        <h3>
                          Recipient:
                          {{
                            shipmentInfo.shippingDoc.ShipmentDetails.Recipient
                              .PersonName
                          }}
                        </h3>
                      </v-list-item>
                      <v-list-item>
                        <h3>
                          Recipient Address:
                          {{
                            shipmentInfo.shippingDoc.ShipmentDetails.Recipient
                              .Address1
                          }}
                          {{
                            shipmentInfo.shippingDoc.ShipmentDetails.Recipient
                              .City
                          }},
                          {{
                            shipmentInfo.shippingDoc.ShipmentDetails.Recipient
                              .State
                          }},
                          {{
                            shipmentInfo.shippingDoc.ShipmentDetails.Recipient
                              .PostalCode
                          }}
                        </h3>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </template>
                <v-flex class="centerButtons mt-2">
                  <v-btn class="mr-2" color="primary" @click="previousStep()"
                    >Previous</v-btn
                  >
                  <v-btn color="primary" @click="reloadPage()">Refresh</v-btn>
                </v-flex>
              </div>
            </pg-section>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper> -->
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import qs from 'qs'
import { v4 as uuidv4 } from 'uuid'
import PatientNameSearch from '@/components/PatientNameSearch/PatientNameSearch.vue'
import Section from '@/components/common/ui/Layout/Section/Section.vue'
import { shippingService } from '@/render/api/zarya'
import config from '@/render/api/zarya/config'

export default {
  components: {
    'pg-section': Section,
    'patient-name-search': PatientNameSearch,
  },
  data() {
    return {
      refresh: 0,
      step: 1,
      iframeUrl: '',
      shipmentInfo: { ShipmentDetails: {} },
      hasData: false,
      dialog: false,
      modalData: {},
      show: false,
      WS_KEY: '',
      hasKey: false,
      orderNumber: '',
      isValid: true,
    }
  },
  watch: {
    // TODO: Can remove if we are no long using steps for shipping process
    // step: function (stepVal) {
    //   if (stepVal === 3) {
    //     this.getShipmentInfoFromDb();
    //   }
    // },
  },
  mounted() {
    this.wsKeyCheck()
    this.addModalData()
  },
  computed: {
    ...mapGetters(['PatientProfile', 'UserProfile', 'PharmacyProfile']),
  },
  methods: {
    addModalData() {
      if (this.WS_KEY === '') {
        this.modalData = {
          firstName: this.UserProfile.firstName,
          lastName: this.UserProfile.lastName,
          phoneNumber: this.PharmacyProfile.phone,
          address1: `${this.PharmacyProfile.address.streetNumber} ${this.PharmacyProfile.address.streetName}`,
          postalCode: this.PharmacyProfile.address.code,
          city: this.PharmacyProfile.address.city,
          province: 'ON',
          company: this.PharmacyProfile.name,
          email: this.PharmacyProfile.email,
          username: this.PharmacyProfile.email,
        }
      }
    },
    async wsKeyCheck() {
      const token = await this.$auth.getTokenSilently()
      await axios
        .put(

          // "https://dev-api.pharmaguide.ca/pharmacy/verifyWsKey", //live
          // "http://localhost:3000/pharmacy/verifyWsKey", //dev
          `${config.api}pharmacy/verifyWsKey`, // dev
          {
            userId: this.$store.getters.PharmacyProfile._id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(async res => {
          if (!res.data) {
            this.dialog = true

            // form will pop up to complete to get key
            // when proceed is clicked in form, call to 2ship api is made for creating wsKey
          } else {
            this.dialog = false
            this.hasKey = true

            // this.WS_KEY = '66F8D0BE-990D-4358-BB83-F72919DBFA60'; //dev test
            this.WS_KEY = res.data
          }
        })
    },
    async selectedPatient() {
      (this.patientProfile = {
        ...this.PatientProfile,
      }),
      (this.patientProfile['DATE OF BIRTH'] = this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD'))
      this.patientProfile.rphfirstname = `${this.UserProfile.firstName} ${this.UserProfile.lastName}`
      this.orderNumber = `${this.$store.getters.PharmacyProfile.accNumber}-${
        this.$store.getters.PatientProfile.PatientID
      }-${uuidv4()}`
      await this.twoShipCall()
      this.hasData = false
      window.open(this.iframeUrl)
    },
    nextStep() {
      this.step++
    },
    previousStep() {
      this.step = 1
    },
    reloadPage() {
      window.location.reload()
    },
    async getShipmentInfoFromDb() {
      try {
        const shipping = await shippingService.getShipment(this.orderNumber).then(res => res)
        this.shipmentInfo = shipping
      } catch (err) {
        console.log(err)
      }
    },

    async twoShipCall() {
      const payload = {
        WS_Key: this.WS_KEY,
        Sender: {
          CompanyName: this.PharmacyProfile.name,
          PersonName: `${this.UserProfile.firstName} ${this.UserProfile.lastName}`,
          Country: 'CA',
          State: 'ON',
          City: this.PharmacyProfile.address.city,
          PostalCode: this.PharmacyProfile.address.code,
          Address1: `${this.PharmacyProfile.address.streetNumber} ${this.PharmacyProfile.address.streetName}`,
          Address2: this.PharmacyProfile.address.unit,
          Telephone: this.PharmacyProfile.phone,
          Email: (this.PharmacyProfile && this.PharmacyProfile.email) || '',
          IsResidential: false,
        },
        Recipient: {
          PersonName: `${this.PatientProfile['FIRST NAME']} ${this.PatientProfile['LAST NAME']}`,
          Country: 'CA',
          State: 'ON',
          City: this.PatientProfile['CITY/TOWN'],
          PostalCode: this.PatientProfile['POSTAL CODE'],
          Address1: `${this.PatientProfile['STREET NUMBER']} ${this.PatientProfile['STREET NAME']}`,
          Address2: this.PatientProfile['UNIT NUMBER'],
          Telephone: this.PatientProfile['TELEPHONE NUMBER'],
          Email: this.PatientProfile['EMAIL ADDRESS'],
          IsResidential: true,
        },
        LocationId: '0',
        Packages: [
          {
            Weight: 2,

            // Width: 12,
            // Length: 12,
            // Height: 12,
          },
        ],
        Skids: [
          {
            Weight: 0,
          },
        ],
        OrderNumber: this.orderNumber,

        // CallbackURL: "https://dev-api.pharmaguide.ca/shipping", //live
        // CallbackURL: `https://0f84c7af91ee.ngrok.io/shipping`, //dev test
        CallbackURL: `${config.api}shipping`, // dev
        RequestPickup: false,
        EmailLabels: false,
        EmailsList: ['walid@pharmaguide.ca'],
        Webhooks: [
          {
            // Uri: "https://dev-api.pharmaguide.ca/shipping", //live
            // Uri: "https://0f84c7af91ee.ngrok.io/shipping", //dev
            Uri: `${config.api}shipping`, // dev
            Username: 'string',
            Password: 'string',
            Type: 'ShipmentStatusChange',
          },
        ],
        CheckAndDeleteFromOnHold: true,
      }
      await axios
        .post('https://ship2api.2ship.com/api/GetEditURL_V1', { ...payload }) // -> live
        // .post("https://api.2ship.com/api/GetEditURL_V1", { ...payload }) //dev
        .then(async res => {
          const url = res.data.TwoShipURL
          const urlParams = await url.substring(url.indexOf('?') + 1)

          // let urlId = await urlParams.substring(urlParams.indexOf("=") + 1);
          // old version
          // this.iframeUrl = `https://ship2.2ship.com/15/ship/standard/Index/${urlId}/Import?HideMenu=true&RemovePadding=true`;
          // this.iframeUrl = `https://ship2.2ship.com/pharmaguide/ship/standard/Index/${urlId}/Import/?HideMenu=true&RemovePadding=true`;
          this.iframeUrl = `https://ship2.2ship.com/io2ship.aspx?HideMenu=true&RemovePadding=true&${urlParams}` // --> live
          // this.iframeUrl = `https://ship.2ship.com/io2ship.aspx?HideMenu=true&RemovePadding=true&${urlParams}`; //dev
          // 9A4C24F2-F70E-43EC-93E7-C4B49684ACC6 real ws-keyfor emad
          // 66F8D0BE-990D-4358-BB83-F72919DBFA60 dev testing ws-key for emad
        })
    },
    async getWsKey() {
      const payload = {
        WS_Key: '2A9BF143-B8DE-4569-AAEC-8E63FB3BFA2E',
        UserName: this.PharmacyProfile.email,
        Password: `${Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2)}`,
        Email: this.PharmacyProfile.email,
        Company: this.PharmacyProfile.name,
        FirstName: this.UserProfile.firstName,
        LastName: this.UserProfile.lastName,
        City: this.PharmacyProfile.address.city,
        Address1: `${this.PharmacyProfile.address.streetNumber} ${this.PharmacyProfile.address.streetName}`,
        CountryCode: 'CA',
        PostalCode: this.PharmacyProfile.address.code,
        Province: 'ON',
        Telephone: this.PharmacyProfile.phone,
      }

      await axios({
        method: 'post',
        url: 'https://ship2.2ship.com/signup/user/create',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; chartset=UTF-8',
          Accept: 'application/json, text/javascript, */*; q=0.01',
          'accept-language': 'en-US,en;q=0.9,ro;q=0.8;hu;q=0.7',
        },

        data: qs.stringify(payload),
      })
        .then(async res => {
          this.WS_KEY = res.data
          const token = await this.$auth.getTokenSilently()
          await axios
            .put(

              // "http://dev-api.pharmaguide.ca:3000/pharmacy/addWsKey", //live
              // "http://localhost:3000/pharmacy/addWsKey", //dev
              `${config.api}pharmacy/addWsKey`, // dev
              {
                wsKey: this.WS_KEY,
                accNumber: this.$store.getters.UserProfile.accreditationNumbers[0][0],
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            .then(res => {
              this.dialog = false
              this.hasKey = true
            })
        })
        .catch(err => console.log(err))
    },
  },
}
</script>
<style scoped>
h3 {
  /* font-weight: 400; */
  font-size: 16px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
#noPadding {
  padding: 0;
}
.fixedElement {
  /* position: fixed; */
  padding: 1% 2%;
  width: 100%;
  z-index: 20;
}
.centerButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
</style>
