<template>
  <v-form
    v-model="valid"
    class="multi-col-validation"
    @submit.native="updateList"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-text-field
            v-model="patient.lastName"
            label="Last Name"
            outlined
            dense
            placeholder="Last Name"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
        >
          <v-text-field
            v-model="patient.firstName"
            label="First Name"
            outlined
            dense
            placeholder="First Name"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            type="submit"
            class="mx-2"
          >
            Submit
          </v-btn>
          <v-btn
            color="error"
            type="reset"
            class="mx-2"
            @click="newPatient"
          >
            Reset
          </v-btn>
        </v-col>
      </v-row>
      <v-list
        v-show="!isLoading && list.length"
        rounded
        class="overflow-y-auto"
        max-height="300"
      >
        <v-divider inset></v-divider>
        <v-list-item-content>
          <v-list-item
            v-for="(paitent, index) in list"
            :key="index"
            @click="processPatientInfo(paitent)"
          >
            <v-list-item-avatar>
              <v-icon
                medium
                color="primary"
                filled
                dark
              >
                folder_open
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ paitent.FirstName }} {{ paitent.LastName }}</v-list-item-title>
            <v-list-item-title>
              DOB:
              {{ $moment(paitent.DOB).format("DD-MM-YYYY") }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-content>
      </v-list shaped>
      <v-list v-show="!isLoading && hasSearched && !list.length && msg">
        <v-list-item ripple>
          {{ msg }}
        </v-list-item>
      </v-list>
      <v-dialog
        v-model="isDialogVisible"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="pt-3">
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="isLoading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="pt-3">
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="successload"
        hide-overlay
        width="300"
      >
        <v-alert
            type="success"
            class="mb-0"
        >Patient Loaded</v-alert>
      </v-dialog>
    </v-container>
  </v-form>
</template>

<script>
// import { ref } from '@vue/composition-api'
import { Promise } from 'bluebird'
import { debounce } from 'lodash'

export default {
  name: 'Patientnamesearch',

  data() {
    return {
      loadProfile: false,
      isDialogVisible: false,
      successload: false,
      progress: 0,
      list: [],
      search: {},
      msg: '',
      hasSearched: false,
      isLoading: false,
      patient: {
        firstName: '',
        lastName: '',
        dob: '',
      },
      valid: false,
    }
  },
  methods: {
    updateList(e) {
      e.preventDefault()

      this.hasSearched = false
      this.msg = ''
      this.list = [] // always reset the list

      if (this.patient.firstName.length < 2 && this.patient.lastName.length < 2) {
        return
      }

      this.debounceFetchPatientsSearch()

      return false
    },
    debounceFetchPatientsSearch() {
      // already initialized ? then use it
      if (this.debounceFunc) {
        this.debounceFunc()
      } else {
        // initialize it
        this.debounceFunc = debounce(() => {
          this.isLoading = true
          this.$root.$system.DBAdapterConn.getSearchPatientsByName({
            firstName: this.patient.firstName,
            lastName: this.patient.lastName,
          }).then(
            results => {
              this.isLoading = false
              if (results.length) {
                this.list = results
              } else {
                this.hasSearched = true
                this.msg = 'No data found'
              }
            },

            // (err)=>{
            //   this.msg = 'Error: please try again.';
            // }
          )
        }, 200)
        this.debounceFunc()
      }
    },
    processPatientInfo(patient) {
      this.isDialogVisible = true

      this.loadProfile = true
      this.$emit('search-patient')

      Promise.delay(20)
        .then(() => {
          this.progress = 75
          this.$store.dispatch('resetPatientData')
          this.resetState()

          // do the request
          return this.$root.$system.DBAdapterConn.getPatientData(patient.PatientID)
        })
        .then(async patientData => {
          this.progress = 100
          await this.$store.dispatch('savePatientData', patientData)

          return patientData.PatientID
        })
        .then(PatientID => {
          this.routePatient(PatientID)
        })
    },
    routePatient(PatientID) {
      return Promise.delay(20)
        .then(() => {
          this.progress = 100
        })
        .delay(20)
        .then(() => {
          this.resetState()
          this.$emit('selected-patient', PatientID)
          this.isDialogVisible = false
          this.successload = true
          window.setTimeout(() => {
            this.successload = false

            // console.log('hide alert after 3 seconds')
          }, 1000)

          // this.$router.push({
          //   name: this.$route.query.next,
          //   query: {
          //     patient: this.$route.query.options?.patient || false,
          //     patientID: PatientID,
          //     c: Math.random()
          //   }
          // }).catch(err => {
          //   // Ignore the vuex err regarding  navigating to the page they are already on.
          //   if (
          //     err.name !== 'NavigationDuplicated' &&
          //     !err.message.includes('Avoided redundant navigation to current location')
          //   ) {
          //     // But print any other errors to the console
          //     console.error(err);
          //   }
          // });
        })
    },
    newPatient() {
      this.$store.dispatch('resetPatientData')
      this.resetState()
      location.reload()
    },
    resetState() {
      this.progress = 0
      this.loadProfile = false
      this.list = []
      this.patient.firstName = ''
      this.patient.lastName = ''
      this.medicationList = ''
      this.patientData = ''
      this.patientProfile = ''
    },
  },
}
</script>
